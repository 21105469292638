<div class="row">
    <div class="col-md-6" *ngIf="companyEMR && companyEMR.emR_Id == 1">
        <div class="box box-primary" >
            <div class="box-header with-border">
                <h3 class="box-title">Process Log</h3>
                <div class="box-tools pull-right">
                    <button class="btn btn-box-tool" type="button" data-widget="collapse">
                        <i class="fa fa-minus"></i>
                    </button>
                    <button class="btn btn-box-tool" type="button" data-widget="remove">
                        <i class="fa fa-times"></i>
                    </button>
                </div>
            </div>
            <div class="box-body">
                <div>
                    <label>Charts Pending OCR Processing - total:{{pendingOCRCount}}, new:{{pendingRecentOCRCount}}</label>
                </div>
                <div class="table-responsive">
                    <ngx-datatable class="material"
                                   [rows]="rowsProcessLog_old"
                                   [headerHeight]="50"
                                   [footerHeight]="50"
                                   rowHeight="auto"
                                   [loadingIndicator]="loadingTable"
                                   [externalPaging]="true"
                                   [limit]="page.pageSize"
                                   [count]="page.count"
                                   [offset]="page.pageNumber"
                                   (page)="setPage($event)"
                                   style="display: flex; flex-direction: column;">
                        <ngx-datatable-column name="Error" [width]="200" >
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <div>
                                    <span *ngIf="!row.successFlag && (!row.status || row.status == 'completed')" (click)="showErrorDetail(row)" class="cursor"> check </span>
                                </div>                                
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="File Name" [width]="250">
                            <ng-template let-row="row" ngx-datatable-cell-template>                               
                                <div style="display:flex; align-items: center">
                                    <span [ngClass]="row.processId == 0 ? 'missing' : (row.status && row.status === 'running') ? 'processing' : ''">{{row.sourceFileName}}</span>
                                    <button *ngIf="row.processId == 0" class="btn-check-exist" (click)="checkFileExist(row)">Check For File</button>
                                </div>
                                <div *ngIf="row.sourceProcessLogForUpdate">
                                    <sub >{{row.sourceProcessLogForUpdate.sourceFileName}}</sub>                                    
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Result" [width]="250">
                            <ng-template let-row="row" ngx-datatable-cell-template>

                                <div class="sourceProcessLog">
                                    <div style="display:flex" *ngIf="row.status && row.status == 'running'">
                                        <span [ngClass]="row.processId == 0 ? 'missing' :'' "> Processing  </span><mat-spinner style="margin-left:10px; vertical-align:bottom" diameter="15"></mat-spinner>
                                        <span *ngIf="row.processMqtt">{{row.processMqtt}}</span>
                                    </div>
                                    <div class="sourceProcessLogNew">
                                        <span *ngIf="!row.status || row.status == 'completed'" [ngClass]="row.processId == 0 ? 'missing' : ''"> {{row.processResult}}</span>
                                    </div>

                                    <div class="sourceProcessLogUpdate">
                                        <span style="font-size: 10px;" *ngIf="row.sourceProcessLogForUpdate"> {{row.sourceProcessLogForUpdate.processResult}}</span>
                                        <span style="cursor: pointer; font-size: 10px;" *ngIf="!row.sourceProcessLogForUpdate || (row.sourceProcessLogForUpdate && !row.sourceProcessLogForUpdate.successFlag)" (click)="rerunImportFileUpdate(row)">retry </span>
                                    </div>
                                </div> 
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Processed Date" [width]="200">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <span [ngClass]="row.processId == 0 ? 'missing' : ''"> {{row.processedDate}}</span>
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>


                    <table class="table table-primary">   
                        <thead>
                            <tr>
                                <th scope="col">File Name</th>
                                <th scope="col">Result</th>
                                <th scope="col">Scheduler DateTime</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="!loadingTableMissing">
                                <tr *ngFor="let row of rowsProcessLog">
                                    <th scope="row">
                                        <div style="display:flex; align-items: center">
                                            <span [ngClass]="row.processId == 0 ? 'missing' : (row.status && row.status === 'running') ? 'processing' : ''">
                                                {{ row.status && row.status === 'running' ? '' : 'Missing ' }} {{ getFileName(row) }}
                                            </span>
                                                <button *ngIf="row.processId == 0" class="btn-check-exist" (click)="checkFileExist(row)">Check For File</button>
                                                <button *ngIf="row.processId == 0 && row.isCheckFileExist" class="btn-check-exist" (click)="rerunImport(row)">Rerun Import</button>
                                        </div>
                                    </th>
                                    <td>{{row.processResult}}</td>
                                    <td>{{row.processedDate| date:'mediumDate'}} 05:30 AM (CST)</td>
                                </tr>
                            </ng-container>
                            <tr  *ngIf="loadingTableMissing">
                                <td colspan="3" style="text-align: center">Loading...</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div [ngClass]="{'col-md-10 col-md-offset-1': companyEMR == null || companyEMR.emR_Id != 1, 'col-md-6': companyEMR != null && companyEMR.emR_Id == 1 }">
        <div class="box box-primary" *ngIf="companyEMR && companyEMR.emR_Id == 2">
            <div class="box-header with-border">
                <h3 class="box-title">Process Log</h3>
                <div class="box-tools pull-right">
                    <button class="btn btn-box-tool" type="button" data-widget="collapse">
                        <i class="fa fa-minus"></i>
                    </button>
                    <button class="btn btn-box-tool" type="button" data-widget="remove">
                        <i class="fa fa-times"></i>
                    </button>
                </div>
            </div>
            <div class="box-body">
                <div>
                    <label>Charts Pending OCR Processing - total:{{pendingOCRCount}}, new:{{pendingRecentOCRCount}}</label>
                </div>
            </div>
        </div>


        <div class="box box-primary" *ngIf="companyEMR && companyEMR.emR_Id == 2">
            <div class="box-header with-border">
                <h3 class="box-title">Import Log -  {{visitCountsPreviousDay.currentTime}}</h3>
                <div class="box-tools pull-right">
                    <button class="btn btn-box-tool" type="button" data-widget="collapse">
                        <i class="fa fa-minus"></i>
                    </button>
                    <button class="btn btn-box-tool" type="button" data-widget="remove">
                        <i class="fa fa-times"></i>
                    </button>
                </div>
            </div>
            <div class="box-body">
                <div>
                    <label>Imported: {{visitCountsPreviousDay.imported}}  -  Running: {{visitCountsPreviousDay.running}}  -  Not Finalized: {{visitCountsPreviousDay.notFinalized}}</label>
                </div>
            </div>
        </div>

        <div class="box box-info">
            <div class="box-header with-border">
                <h3 class="box-title"> {{(companyEMR && companyEMR.emR_Id)? companyEMR.emR_Name + ' and AMD Status' : 'Loading...'}}</h3>

                <div style="left:50%; position: absolute; top: 5px;">
                    <div class="date-time-tool">
                        <mat-icon (click)="changeDateRange(false)">keyboard_arrow_left</mat-icon>
                        <span>{{ chartStartDate | date:'MM/dd/yyyy' }} - {{ chartEndDate | date:'MM/dd/yyyy' }}</span>
                        <mat-icon (click)="changeDateRange(true)" *ngIf="!isChartCurrentMonth" >keyboard_arrow_right</mat-icon>
                    </div>
                </div>
                <div class="box-tools pull-right">
                    <button class="btn btn-box-tool" type="button" data-widget="collapse">
                        <i class="fa fa-minus"></i>
                    </button>
                    <button class="btn btn-box-tool" type="button" data-widget="remove">
                        <i class="fa fa-times"></i>
                    </button>
                </div>
            </div>
            <div class="box-body">
                <div style="text-align: center;" *ngIf="isLoadingChart">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
                <div class="chart">
                    <canvas id="processChart" #processChart></canvas>
                </div>
            </div>
        </div>
        <div class="box box-warning">
            <div class="box-header with-border">
                <h3 class="box-title">Service Status</h3>
                <div class="box-tools pull-right">
                    <button class="btn btn-box-tool" type="button" data-widget="collapse">
                        <i class="fa fa-minus"></i>
                    </button>
                    <button class="btn btn-box-tool" type="button" data-widget="remove">
                        <i class="fa fa-times"></i>
                    </button>
                </div>
            </div>
            <div class="box-body">
                <div class="table-responsive">
                    <ngx-datatable class="material"
                                   [rows]="serverMachines"
                                   [headerHeight]="50"
                                   [footerHeight]="50"
                                   rowHeight="auto"
                                   [loadingIndicator]="loadingTableServerMachine"
                                   [externalPaging]="true"
                                   [limit]="pageSever.pageSize"
                                   [count]="pageSever.count"
                                   [offset]="pageSever.pageNumber"
                                   (page)="setPageServerMachine($event)">
                        <ngx-datatable-column name="Name" [width]="600">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <div style="display:flex; align-items: center">
                                    <span>{{row.name}}</span>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Status" [width]="100">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <div class="row-status">
                                    <div class="dot" [ngClass]="row.isOnline ? 'dot-green' : 'dot-red' "></div>
                                    <span>
                                        {{row.isOnline? 'Online ' : 'Offline'}}
                                    </span>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </div>
        </div>
        <div class="box box-danger">
            <div class="box-header with-border">
                <h3 class="box-title">Physicians (Bad Data)</h3>  <span><button (click)="syncWithAmd()">Sync with amd</button></span>
                <div class="box-tools pull-right">
                    <button class="btn btn-box-tool" type="button" data-widget="collapse">
                        <i class="fa fa-minus"></i>
                    </button>
                    <button class="btn btn-box-tool" type="button" data-widget="remove">
                        <i class="fa fa-times"></i>
                    </button>
                </div>
            </div>
            <div class="box-body">
                <div class="table-responsive table-physician">
                    <table class="table table-primary" *ngIf="!syncingWithAmd">
                        <thead>
                            <tr>
                                <th scope="col">PhysicianId</th>
                                <th scope="col">DisplayName</th>
                                <th scope="col">AmdProviderId</th>
                                <!--<th scope="col">OfficeKey</th>-->
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="!isLoadingPhysician">
                                <tr *ngFor="let row of physicians">
                                    <th scope="row">
                                        {{row.pvPhysicianId}}
                                    </th>
                                    <td>{{row.displayName}}</td>
                                    <td>{{row.amProviderId}}</td>
                                    <!--<td>{{row.officeKey}}</td>-->
                                </tr>
                            </ng-container>
                            <tr *ngIf="isLoadingPhysician">
                                <td colspan="3" style="text-align: center">Loading...</td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="syncingWithAmd">
                        syncing..
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
