<div class="alert alert-warning" id="globalmsg" hidden>
    <strong>Warning!</strong> You have unsaved Rules.
</div>

<div class="row top-buffer" cdkDropListGroup>
    <div class="col-lg-3">
        <div class="panel panel-default">
            <div class="panel-heading">
                <h3>Rule List</h3>
            </div>
            <div class="panel-body">
                <div class="box box-danger">
                    <!-- <div style="text-align: end;margin-top: 10px;">
                        <button *ngIf="isSaveSorting" class="save-sorting" (click)="saveSorting()">Save</button>
                    </div> -->
                    <mat-slide-toggle style="font-size:9pt; float:right" [(ngModel)]="isActiveAllRule"
                                      (ngModelChange)="changeStateAllRule($event)">ACTIVATE ALL</mat-slide-toggle>
                    <div class="box-header with-border">

                    </div>
                    <div class="box-body" cdkDropList [cdkDropListData]="ruleList" (cdkDropListDropped)="drop($event)">
                        <ng-container *ngIf="ruleList?.length > 0">
                            <div class="form-inline top-buffer ruleItem" *ngFor="let rule of ruleList" cdkDrag>
                                <button type="button" class="btn mr-1" [disabled]="!rule.hasActionSet" matTooltip="Linked to an action set"
                                        arial-label="left Align">
                                    <span class="glyphicon glyphicon-link" arial-hidden="true" data-></span>
                                </button>
                                <button type="button" class="btn btn-default btnDeleteRule btn-danger mr-1"
                                        arial-label="left Align" (click)="deleteRule(rule.ruleName)">
                                    <span class="glyphicon glyphicon-trash" arial-hidden="true" data-></span>
                                </button>
                                <button type="button" class="btn btn-default btnEditRule mr-1" arial-label="left Align"
                                        (click)="editRule(rule.ruleName)">
                                    <span class="glyphicon glyphicon-pencil" arial-hidden="true"></span>
                                </button>
                                <mat-slide-toggle style="font-size:9pt" [(ngModel)]="rule.active"
                                                  (ngModelChange)=" activeRule(rule, rule.active); checkActiveAll();">{{rule.ruleName}}</mat-slide-toggle>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="ruleList?.length == 0">
                            <div class="alert alert-info">There is no rule defined yet. You can save a new rule.</div>
                        </ng-container>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-9">
        <div class="panel panel-default">
            <div class="panel-heading">
                <h3>
                    Rule Detail
                </h3>
            </div>
            <div class="panel-body">
                <div class="box box-warning">

                    <div class="box-header with-border">
                        <div class="col-lg-9 align-self-end">
                            <div class="row">
                                <button type="button" class="btn btn-success mr-1" id="btnAdd"
                                        (click)="addNewRule()">
                                    Add New Rule
                                </button>
                                <button type="button" class="btn btn-primary mr-1" id="btnSave"
                                        (click)="saveRuleTable()">
                                    Save
                                </button>
                                <!-- <button type="button" class="btn btn-info mr-1" id="btnUndo"
                                    (click)="cleanRuleTable()">Undo</button> -->
                            </div>
                        </div>
                    </div>

                    <div id="ruleContent" *ngIf="isShowRuleCotent" style="margin-top: 15px;">
                        <div class="box-body">
                            <div class="alert alert-danger alert-dismissible" role="alert" id='ruleMsg'
                                 *ngIf="isShowMessage">
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <strong>Attention! {{stringMsg}}</strong>
                            </div>
                            <div *ngIf="isShowAlertDelete" class="alert alert-info">
                                There is no rule defined yet. You
                                can save a new rule.
                            </div>
                            <div style="display: inline-flex; width: 100%;">
                                <div class="form-inline">
                                    <label data-error="wrong" data-success="right" for="ruleName"
                                           style="margin-right: 5px;">Rule Name:</label>
                                    <input type="text" id="ruleName" class="form-control validate" required
                                           [(ngModel)]="ruleName">
                                </div>
                                <div style="width: 100%;" *ngIf="currentCodeReviewRule">

                                    <div class="form-group">
                                        <label>Subject</label>
                                        <input class="form-control" type="text" [(ngModel)]="currentCodeReviewRule.subject">
                                    </div>
                                    <div class="form-group">
                                        <label>Description</label>
                                        <input class="form-control" type="text" [(ngModel)]="currentCodeReviewRule.description">
                                    </div>
                                </div>
                            </div>

                        </div>
                        <hr />
                        <table class="table table-borderless" id="ruleDetail">
                            <thead>
                                <tr>
                                    <td colspan="5">
                                        <button type="button" class="btn btn-default btnAddRow mr-1"
                                                arial-label="left Align" (click)="addGroup()">
                                            Add New Group
                                        </button>
                                    </td>
                                    <td colspan="5">

                                        <mat-form-field style="width: 100%" appearance="fill" *ngIf="bulkActionSets?.length > 0">
                                            <mat-label>Run Bulk Action Set</mat-label>
                                            <mat-select [(ngModel)]="selectedBulkAction"
                                                        (ngModelChange)="changeBulkAction()">
                                                <mat-option value="">-Select Action Set-</mat-option>
                                                <mat-option *ngFor="let item of bulkActionSets" [value]="item">
                                                    {{item.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="col">
                                        <!-- <button type="button" class="btn btn-default btnAddRow mr-1"
                                            arial-label="left Align" (click)="addRow()">
                                            <span class="glyphicon glyphicon-plus" arial-hidden="true" data-></span>
                                        </button> -->
                                    </th>
                                    <th scope="col">Field</th>
                                    <th scope="col">Operator</th>
                                    <th scope="col">Value</th>
                                    <th scope="col">And/Or</th>
                                    <th scope="col">

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let g of groupCodeReview; let indG = index">
                                    <tr class="tr_clone" id="id1" *ngFor="let item of g.ruleList; let i = index">
                                        <td>
                                            <button type="button" class="btn btn-default btnDeleteRow mr-1"
                                                    arial-label="left Align" (click)="deleteRow(indG, i, g, item)">
                                                <span class="glyphicon glyphicon-remove" arial-hidden="true"
                                                      data-></span>
                                            </button>
                                        </td>
                                        <td>
                                            <mat-form-field>
                                                <mat-select [(ngModel)]="item.operatorTargetName">
                                                    <!-- <mat-option value="0">Select</mat-option> -->
                                                    <mat-option value="ProcCodes">A CPT</mat-option>
                                                    <mat-option value="CptCount">CPT Count</mat-option>
                                                    <!--<mat-option value="EMCode">An EM</mat-option>-->
                                                    <mat-option value="ICDCodes">An ICD</mat-option>
                                                    <mat-option value="ICDCount">ICD Count</mat-option>
                                                    <!--<mat-option value="VisitProcCode[Modifier]">A CPT Modifier</mat-option>
    <mat-option value="EMModifier">A EM Modifier</mat-option>
    <mat-option value="ChartText">Chart Text</mat-option>-->
                                                    <mat-option value="Modifers">A Modifer</mat-option>
                                                    <mat-option value="FinClass" selected>FinClass</mat-option>
                                                    <mat-option value="OfficeKey">Office Key</mat-option>
                                                    <mat-option value="DayOfWeek">Day Of Week</mat-option>
                                                    <mat-option value="TimeOfDay">Time Of Day</mat-option>
                                                    <mat-option value="Physician">Physician</mat-option>
                                                    <mat-option value="NonModifiedCPT">A Non Modified CPT</mat-option>
                                                    <mat-option value="ModifiedCPT">A Modified CPT</mat-option>
                                                    <mat-option value="Clinic">Clinic</mat-option>
                                                    <mat-option value="PatientAge">Patient Age</mat-option>
                                                    <mat-option value="ChartText">Chart Text</mat-option>
                                                    <mat-option *ngIf="emrId === 2" value="OrderAndProc">Order and Proc</mat-option>
                                                    <mat-option value="ChartVitals">Chart Vitals</mat-option>
                                                    <mat-option value="ChartReviewOfSys">Chart Review Of Sys</mat-option>
                                                    <mat-option value="NewPatientVisit">New Patient Visit </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <ng-container *ngIf="item.operatorTargetName === 'ChartText'">
                                                <mat-form-field>
                                                    <mat-label>Select a Section here</mat-label>
                                                    <mat-select [(ngModel)]="item.chartOcrSectionId">
                                                        <mat-option *ngFor="let s of ocrSections" [value]="s.id">
                                                            {{s.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </ng-container>
                                            <ng-container *ngIf="item.operatorTargetName === 'ChartVitals'">
                                                <mat-form-field>
                                                    <mat-label>Select a Vital here</mat-label>
                                                    <mat-select [(ngModel)]="item.chartOcrSectionId">
                                                        <mat-option *ngFor="let s of ocrVitalsSections" [value]="s.id">
                                                            {{s.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </ng-container>
                                            <ng-container *ngIf="item.operatorTargetName === 'ChartReviewOfSys'">
                                                <mat-form-field>
                                                    <mat-label>Select Review Type Here</mat-label>
                                                    <mat-select [(ngModel)]="item.chartOcrSectionId">
                                                        <mat-option *ngFor="let s of ocrReviewOfSysSections" [value]="s.id">
                                                            {{s.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </ng-container>
                                            <ng-container *ngIf="item.operatorTargetName === 'OrderAndProc'">
                                                <mat-form-field>
                                                    <mat-label>Select Order/Proc here</mat-label>
                                                    <mat-select [(ngModel)]="item.chartOcrSectionId">
                                                        <mat-option *ngFor="let s of ocrOrderProceduresSections" [value]="s.id">
                                                            {{s.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </ng-container>
                                        </td>
                                        <td>
                                            <mat-form-field>
                                                <mat-select [(ngModel)]="item.operator">
                                                    <!-- <mat-option value="0">Select</mat-option> -->
                                                    <ng-container *ngIf="item.operatorTargetName !== 'ChartReviewOfSys'">
                                                        <mat-option value="Is">Is</mat-option>
                                                    </ng-container>
                                                    <ng-container *ngIf="item.operatorTargetName === 'ChartReviewOfSys' || item.operatorTargetName ==='OrderAndProc'" >
                                                        <mat-option value="Contains">Contains</mat-option>
                                                        <mat-option value="NotContains">Not Contains</mat-option>
                                                    </ng-container>
                                                    <ng-container *ngIf="item.operatorTargetName == 'CptCount'
                                                                  || item.operatorTargetName == 'ICDCount'
                                                                  || item.operatorTargetName == 'TimeOfDay'
                                                                   || item.operatorTargetName == 'PatientAge'
                                                                  || item.operatorTargetName == 'ChartVitals'">
                                                        <mat-option value="LessThan">Less Than</mat-option>
                                                        <mat-option value="LessThanEqual">Less Than or Equal To</mat-option>
                                                        <mat-option value="GraterThen">Greater Than</mat-option>
                                                        <mat-option value="GraterThenEqual">Greater Than or Equal To</mat-option>
                                                    </ng-container>
                                                    <ng-container *ngIf="item.operatorTargetName !== 'CptCount'
                                                                  && item.operatorTargetName !== 'ICDCount'
                                                                  && item.operatorTargetName !== 'ChartReviewOfSys'
                                                                  &&  item.operatorTargetName != 'PatientAge'">
                                                        <mat-option *ngIf="item.operatorTargetName != 'TimeOfDay'" value="NotIs">Is Not</mat-option>
                                                        <mat-option *ngIf="item.operatorTargetName == 'ProcCodes' || item.operatorTargetName == 'Modifers' || item.operatorTargetName == 'ICDCodes' || item.operatorTargetName == 'ChartText'" value="Contains">Contains</mat-option>
                                                        <mat-option *ngIf="item.operatorTargetName == 'ChartText' || item.operatorTargetName == 'ProcCodes' || item.operatorTargetName == 'ICDCodes'" value="NotContains">Not Contains</mat-option>
                                                        <mat-option *ngIf="item.operatorTargetName != 'Physician' && item.operatorTargetName != 'DayOfWeek' && item.operatorTargetName != 'TimeOfDay' && item.operatorTargetName != 'Clinic' && item.operatorTargetName != 'ChartVitals' && item.operatorTargetName != 'NewPatientVisit'" value="StartsWith">Starts With</mat-option>
                                                        <mat-option *ngIf="item.operatorTargetName != 'DayOfWeek' && item.operatorTargetName == 'TimeOfDay'" value="NotStartsWith">Does Not Start With</mat-option>
                                                        <mat-option *ngIf="item.operatorTargetName == 'ProcCodes' || item.operatorTargetName == 'ICDCodes'" value="EndsWith">Ends With</mat-option>
                                                        <mat-option *ngIf="item.operatorTargetName == 'FinClass'" value="In" selected>In</mat-option>
                                                    </ng-container>
                                                </mat-select>
                                            </mat-form-field>
                                        </td>

                                        <td>
                                            <ng-container *ngIf="item.operatorTargetName === 'CptCount' || item.operatorTargetName === 'ICDCount' || item.operatorTargetName == 'PatientAge'">
                                                <input [(ngModel)]="item.operatorTargetValue" type="number"
                                                       class="form-control ruleVal" />
                                            </ng-container>
                                            <ng-container *ngIf="!['CptCount','ICDCount', 'FinClass', 'Physician','NonModifiedCPT','ModifiedCPT','Clinic','PatientAge','NewPatientVisit'].includes(item.operatorTargetName)">
                                                <input [(ngModel)]="item.operatorTargetValue" type="text"
                                                       class="form-control ruleVal" />

                                            </ng-container>
                                            <ng-container *ngIf="item.operatorTargetName === 'NonModifiedCPT'">
                                                <input [(ngModel)]="item.operatorTargetValue" type="text"
                                                       class="form-control ruleVal" /> <b> Not Yet Modified To:</b>
                                                <input [(ngModel)]="item.operatorTargetValue_2" type="text"
                                                       class="form-control ruleVal" />
                                            </ng-container>
                                            <ng-container *ngIf="item.operatorTargetName === 'ModifiedCPT'">
                                                <input [(ngModel)]="item.operatorTargetValue" type="text"
                                                       class="form-control ruleVal" /> <b> That Modified To:</b>
                                                <input [(ngModel)]="item.operatorTargetValue_2" type="text"
                                                       class="form-control ruleVal" />
                                            </ng-container>

                                            <ng-container *ngIf="item.operatorTargetName === 'FinClass' && item.operator == 'In' && item.operatorTargetName !== 'CptCount' && item.operatorTargetName !== 'ICDCount'">
                                                <mat-form-field>
                                                    <mat-select multiple [(ngModel)]="item.operatorTargetValue">
                                                        <mat-option *ngFor="let fc of finClasses" [value]="fc.text">
                                                            {{fc.text}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </ng-container>
                                            <ng-container *ngIf="item.operatorTargetName === 'FinClass' && item.operator != 'In' && item.operatorTargetName !== 'CptCount' && item.operatorTargetName !== 'ICDCount'">
                                                <mat-form-field>
                                                    <mat-select [(ngModel)]="item.operatorTargetValue">
                                                        <mat-option *ngFor="let fc of finClasses" [value]="fc.text">
                                                            {{fc.text}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </ng-container>
                                            <ng-container *ngIf="item.operatorTargetName === 'Physician'">
                                                <mat-form-field>
                                                    <mat-select [(ngModel)]="item.operatorTargetValue">
                                                        <mat-option *ngFor="let p of physicians" [value]="p.pvPhysicianId">
                                                            {{p.displayName}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </ng-container>

                                            <ng-container *ngIf="item.operatorTargetName === 'NewPatientVisit'">
                                                <mat-form-field>
                                                    <mat-select [(ngModel)]="item.operatorTargetValue">
                                                        <mat-option [value]="'true'">True</mat-option>
                                                        <mat-option [value]="'false'">False</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </ng-container>

                                            <ng-container *ngIf="item.operatorTargetName === 'Clinic'">
                                                <mat-form-field>
                                                    <mat-select [(ngModel)]="item.operatorTargetValue">
                                                        <mat-option *ngFor="let c of clinics" [value]="c.id">
                                                            {{c.id}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </ng-container>
                                        </td>

                                        <td>
                                            <mat-form-field>
                                                <mat-select [(ngModel)]="item.logicOperator">
                                                    <mat-option value="and">And</mat-option>
                                                    <mat-option *ngIf="item.operatorTargetName !== 'NonModifiedCPT' && item.operatorTargetName !== 'ModifiedCPT'" value="or" selected>Or</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </td>
                                        <td>
                                            <button type="button" class="btn btn-default btnAddRow mr-1"
                                                    arial-label="left Align" (click)="addRowIndex(indG, i, item)">
                                                <span class="glyphicon glyphicon-plus" arial-hidden="true" data-></span>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <mat-form-field>
                                                <mat-select [(ngModel)]="g.logicOperator">
                                                    <mat-option value="and">And</mat-option>
                                                    <mat-option value="or" selected>Or</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </td>
                                        <td style=" vertical-align: middle;  text-align: end;">
                                            <button type="button" class="btn btn-default btnDeleteRow mr-1" arial-label="left Align" (click)="removeGroup(indG,g)">
                                                Remove Group
                                            </button>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="panel panel-default">
            <div class="panel-heading">
                <h3>
                    Own Rule List
                </h3>
            </div>
            <div class="panel-body">
                <div class="box box-success">
                    <div class="box-body">
                        <ng-container *ngIf="ruleOwnList?.length > 0">
                            <div class="form-inline ruleOwnList" *ngFor="let rule of ruleOwnList">
                                <div class="ruleOwnItem">
                                    <mat-slide-toggle [(ngModel)]="rule.active" (ngModelChange)="activeRuleOwn(rule, rule.active)">{{rule.ruleName}}</mat-slide-toggle>
                                    <button type="button" class="btn btn-light" (click)="removeRuleOwn(rule)">
                                        <span class="glyphicon glyphicon-remove" arial-hidden="true" data-></span>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>-->
        <div class="panel panel-default" *ngIf="this.ruleName">
            <div class="panel-heading" style="display: flex;align-items: center;justify-content: space-between;">
                <div>
                    <h3>
                        Test and Show 20 Records match the rule
                    </h3>
                    <span style="color: red;">(Remember save rule updates first)</span>
                </div>
                <div>
                    <button type="button" class="btn btn-primary" (click)="testRuleVisit()">
                        Test
                    </button>
                </div>
            </div>

            <div class="panel-body">
                <div class="box box-success">
                    <div class="box-body" style="max-height: 500px; overflow: auto">
                        <table class="table table-striped" style="margin-top: 10px;">
                            <thead>
                                <tr>
                                    <th scope="col">VisitId</th>
                                    <th scope="col">Office Key</th>
                                    <th scope="col">Proc codes</th>
                                    <th scope="col">ICD Codes</th>
                                    <th scope="col">Finacial Class</th>
                                    <th scope="col">Service Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="!loadingTableVisit && listVisits?.length > 0">
                                    <tr *ngFor="let item of listVisits">
                                        <th scope="row">  {{ item.visitId }}</th>
                                        <td>   {{ item.officeKey }}</td>
                                        <td>
                                            <div *ngFor="let proc of item.visitProcCodes">
                                                {{ proc.quantity }} {{ proc.procCode }} {{ proc.modifier }}
                                            </div>
                                        </td>
                                        <td>
                                            <span [innerHTML]="item.icdCodes"></span>
                                        </td>
                                        <td>
                                            {{ item.pvFinClass }}
                                        </td>
                                        <td>
                                            {{item.serviceDate | date : 'MMM dd yyyy'}}
                                        </td>
                                    </tr>
                                </ng-container>
                                <tr>
                                    <td colspan="6" style="text-align: center;" *ngIf="loadingTableVisit" colspan="6">
                                        Loading...
                                    </td>
                                    <td colspan="6" style="text-align: center;" *ngIf="listVisits?.length <= 0 && !loadingTableVisit">
                                        No data!
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>